import "./menu.css";
import React, { useEffect, useState } from 'react';
import BackToTopButton from './BackToTopButton'; // Adjust the path accordingly


function Elitamenu() {
    const translations = {
        sq: {
            'Pizza:': 'Pizza:',
            'Pizza Margherita': 'Pizza Margherita',
            'Salcë domatesh,mozzarella': 'Salcë domatesh,mozzarella',
            "Pizza Prosciutto":'Pizza Prosciutto',
            "Salcë domatesh,mozzarella,mish i thatë":'Salcë domatesh,mozzarella,mish i thatë',    
            "Pizza Capricciosa":'Pizza Capricciosa',
            "Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha":"Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha",
            "Pizza Tuna": 'Pizza Tuna',
            "Salcë domatesh,mozzarella,qepë,tuna":'Salcë domatesh,mozzarella,qepë,tuna',
            "Pizza 4 Stinët":'Pizza 4 Stinët',
            "Salcë domatesh,mozzarella,mish i thatë,sallamë,,tuna,kërpudha":'Salcë domatesh,mozzarella,mish i thatë,sallamë,,tuna,kërpudha',
            "Pizza Quattro formaggi":'Pizza Quattro formaggi',
            "Salcë domatesh,mozzarella,,parmezan,gorgonzollë,djath i bardhë":'Salcë domatesh,mozzarella,,parmezan,gorgonzollë,djath i bardhë',
            "Pizza Vegetariana":'Pizza Vegetariana' ,
            "Salcë domatesh,mozzarella,domate të kuqe,domate e zezë,speca,ullinj":'Salcë domatesh,mozzarella,domate të kuqe,domate e zezë,speca,ullinj',
            "Pizza Një mijë e një netë":'Pizza Një mijë e një netë',
            "Salcë domatesh,mozzarella,qepë,kërpudha,sallamë,suxhuk,speca":'Salcë domatesh,mozzarella,qepë,kërpudha,sallamë,suxhuk,speca',
            "Pizza Reale":'Pizza Reale',
            "Salcë domatesh,mozzarella,parmezan,mish i thatë,rukola":'Salcë domatesh,mozzarella,parmezan,mish i thatë,rukola',
            "Pizza Calzone":'Pizza Calzone',
            "pizza e mbyllur,Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha":'pizza e mbyllur,Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha',
            "Pizza Funghi":'Pizza Funghi',
            "Salcë domatesh,mozzarella,kërpudha":'Salcë domatesh,mozzarella,kërpudha',
            "Pizza Elita":'Pizza Elita',
            "e përzier":'e përzier',
            "Pizza Familjare":'Pizza Familjare',
            "Salcë domatesh,mozzarella,sallamë,mish i thatë,suxhuk,kërpudha,ullinj":'Salcë domatesh,mozzarella,sallamë,mish i thatë,suxhuk,kërpudha,ullinj',
            "Ujë mineral":'Ujë mineral',
            "Ujë pa gaz":'Ujë pa gaz',
            "Lëng Portokalli":'Lëng Portokalli',
            "Lëng Dredhze":'Lëng Dredhze',
            "Lëng Vishnje":'Lëng Vishnje',
            "Lëng Pjeshke":'Lëng Pjeshke',
            "Lëng Molle":'Lëng Molle',
            "Multivitamin":'Multivitamin',
            "Ice Tea":'Ice Tea',
            "Sallat pemësh":'Sallat pemësh',
            "Akullore":'Akullore',
            "Makiato e madhe":'Makiato e madhe',
            "Kapuçino me shllag":'Kapuçino me shllag',
            "Çaj":'Çaj',
            "Kafe":'Kafe',
            "Qumësht":'Qumësht',
            "Pije freskuese":'Pije freskuese',
            "Pije të nxehta":'Pije të nxehta',
            "Ëmbëlsira":'Ëmbëlsira',
            "Limonadë":'Limonadë',
            "Boronicë":'Boronicë',


        },

        // Macedonian translations
        mk: {
            'Pizza:': 'Пица:',
            'Pizza Margherita': 'Пица Маргарита',
            'Salcë domatesh,mozzarella': 'Доматен coc,моцарела',
            "Pizza Prosciutto":'Пица пршута',
            "Salcë domatesh,mozzarella,mish i thatë":'Доматен coc,моцарела,суво месо',
            "Pizza Capricciosa":' Пица Капричиоза',
            "Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha":"Сос од домати, моцарела, суво месо, салама, печурки",
            "Pizza Tuna":'Пица туна',
            "Salcë domatesh,mozzarella,qepë,tuna":'Сос од домати, моцарела, кромид, туна',
            "Pizza 4 Stinët":'Пица 4 годишни времиња',
            "Salcë domatesh,mozzarella,mish i thatë,sallamë,,tuna,kërpudha":'Сос од домати, моцарела, суво месо, салама, туна, печурки',
            "Pizza Quattro formaggi":'Пица со четири сирење',
            "Salcë domatesh,mozzarella,,parmezan,gorgonzollë,djath i bardhë":'Сос од домати, моцарела, пармезан, горгонзола, бело сирење',
            "Pizza Vegetariana":'вегетаријанска пица',
            "Salcë domatesh,mozzarella,domate të kuqe,domate e zezë,speca,ullinj":'Сос од домати, моцарела, црвени домати, црни домати, пиперки, маслинки',
            "Pizza Një mijë e një netë":'Пица Илјада и една ноќ',
            "Salcë domatesh,mozzarella,qepë,kërpudha,sallamë,suxhuk,speca":'Сос од домати, моцарела, кромид, печурки, салама, колбаси, бибер',
            "Pizza Reale":'Вистинска пица',
            "Salcë domatesh,mozzarella,parmezan,mish i thatë,rukola":'Сос од домати, моцарела, пармезан, суво месо, рукола',
            "Pizza Calzone":'Пица калцоне',
            "pizza e mbyllur,Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha":'затворена пица, сос од домати, моцарела, суво месо, салама, печурки',
            "Pizza Funghi":'Пица со печурки',
            "Salcë domatesh,mozzarella,kërpudha":'Сос од домати, моцарела, печурки',
            "Pizza Elita":'Пица елита',
            "e përzier":'измешани',
            "Pizza Familjare":'Семејна пица',
            "Salcë domatesh,mozzarella,sallamë,mish i thatë,suxhuk,kërpudha,ullinj":'Сос од домати, моцарела, салама, суво месо, колбаси, печурки, маслинки',
            "Ujë mineral":'Газирана вода',
            "Ujë pa gaz":'Негазирана вода',
            "Lëng Portokalli":'сок од портокал',
            "Lëng Dredhze":'Сок од јагода',
            "Lëng Vishnje":'сок од вишна',
            "Lëng Pjeshke":'Сок од праска',
            "Lëng Molle":'сок од јаболко',
            "Multivitamin":'Мултивитамински сок',
            "Ice Tea":'Леден чај',
            "Sallat pemësh":'овошна салата',
            "Akullore":'сладолед',
            "Makiato e madhe":'Големо макијато',
            "Kapuçino me shllag":'Капучино со шлаг',
            "Çaj":'чај',
            "Kafe":'Кафе',
            "Qumësht":'Млеко',
            "Pije freskuese":'ладни пијалоци',
            "Pije të nxehta":'топли пијалоци',
            "Ëmbëlsira":'слатки',
            "Limonadë":'Лимонада',
            "Boronicë":'Боровница',
        },

        // English translations
        en: {
            'Pizza:': 'Pizza:',
            'Pizza Margherita': 'Pizza Margherita',
            'Salcë domatesh,mozzarella': 'Tomato sauce,mozzarella',
            "Pizza Prosciutto":'Pizza Ham',
            "Salcë domatesh,mozzarella,mish i thatë":'Tomato sauce, mozzarella, dried meat',    
            "Pizza Capricciosa":' Pizza Capricciosa',
            "Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha":"Tomato sauce, mozzarella, dried meat, salami, mushrooms",
            "Pizza Tuna":'Pizza Tuna',
            "Salcë domatesh,mozzarella,qepë,tuna":'Tomato sauce, mozzarella, onion, tuna',
            "Pizza 4 Stinët":'Pizza 4 Seasons',
            "Salcë domatesh,mozzarella,mish i thatë,sallamë,,tuna,kërpudha":'Tomato sauce, mozzarella, dried meat, salami, tuna, mushrooms',
            "Pizza Quattro formaggi":'Four cheese pizza',
            "Salcë domatesh,mozzarella,,parmezan,gorgonzollë,djath i bardhë":'Tomato sauce, mozzarella, parmesan, gorgonzola, white cheese',
            "Pizza Vegetariana":'Pizza Vegetariana',
            "Salcë domatesh,mozzarella,domate të kuqe,domate e zezë,speca,ullinj":'Tomato sauce, mozzarella, red tomatoes, black tomatoes, peppers, olives',
            "Pizza Një mijë e një netë":'Pizza One thousand and one nights',
            "Salcë domatesh,mozzarella,qepë,kërpudha,sallamë,suxhuk,speca":'Tomato sauce, mozzarella, onion, mushrooms, salami, sausage, pepper',
            "Pizza Reale":'Real Pizza',
            "Salcë domatesh,mozzarella,parmezan,mish i thatë,rukola":'Tomato sauce, mozzarella, parmesan, dry meat, arugula',
            "Pizza Calzone":'Pizza calzone',
            "pizza e mbyllur,Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha":'closed pizza, tomato sauce, mozzarella, dry meat, salami, mushrooms',
            "Pizza Funghi":'Mushroom pizza',
            "Salcë domatesh,mozzarella,kërpudha":'Tomato sauce, mozzarella, mushrooms',
            "Pizza Elita":'Pizza Elita',
            "e përzier":'mixed',
            "Pizza Familjare":'Family Pizza',
            "Salcë domatesh,mozzarella,sallamë,mish i thatë,suxhuk,kërpudha,ullinj":'Tomato sauce, mozzarella, salami, dry meat, sausage, mushrooms, olives',
            "Ujë mineral":'Mineral water',
            "Ujë pa gaz":'Still water',
            "Lëng Portokalli":'Orange juice',
            "Lëng Dredhze":'Strawberry juice',
            "Lëng Vishnje":'Cherry juice',
            "Lëng Pjeshke":'Peach juice',
            "Lëng Molle":'Apple juice',
            "Multivitamin":'Multivitamin juice',
            "Ice Tea":'Ice Tea',
            "Sallat pemësh":'Fruit salad',
            "Akullore":'ice cream',
            "Makiato e madhe":'Caffe macchiato long',
            "Kapuçino me shllag":'Cappuccino with whipped cream',
            "Çaj":'Tea',
            "Kafe":'Coffee',
            "Qumësht":'Milk',
            "Pije freskuese":'Cold drinks',
            "Pije të nxehta":'Hot drinks',
            "Makiato":'Caffe macchiato',
            "Ëmbëlsira":'Deserts',
            "Limonadë":'Lemonade',
            "Boronicë":'Blueberry',

        }
    };
    const Location = () => {
        // Replace the URL below with the URL you want to go to
        window.location.href = 'https://www.google.com/maps/place/%D0%A0%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD+%22%D0%95%D0%BB%D0%B8%D1%82%D0%B0%22/@41.9445652,20.9261216,18z/data=!4m6!3m5!1s0x1353fb17d0409d23:0x73ba5d7209b3c3f6!8m2!3d41.9445758!4d20.9276183!16s%2Fg%2F11by_l1zr1?hl=sq&entry=ttu';
      }
    function Phone() {
      // Replace the phone number below with the number you want to call
      window.location.href = 'tel:044 394 136';
    }
  
    const [language, setLanguage] = useState('sq'); // Default language
  
    function translate(selectedLanguage) {
      const elementsToTranslate = document.querySelectorAll('[data-translate]');
      elementsToTranslate.forEach((element) => {
        const key = element.getAttribute('data-translate');
        if (translations[selectedLanguage] && translations[selectedLanguage][key]) {
          element.textContent = translations[selectedLanguage][key];
        }
      });
    }
  
    useEffect(() => {
      translate(language);
    }, [language]);
      
    return(
      <>
      <head>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

      </head>
          <div class="menu-container">
        <h1>Menu</h1>
        <div class="buttons">
            <button onClick={() => setLanguage('sq')} data-lang="sq" className="button">Shqip</button>
            <button onClick={() => setLanguage('mk')} data-lang="mk" className="button1">Македонски</button>
            <button onClick={() => setLanguage('en')} data-lang="en" className="button">English</button>
        </div>
    
        <ul>
            <h2>Pizza:</h2>
            <li>
                <span class="span" data-translate="Pizza Margherita">Pizza Margherita</span>
                <span class="cmimi">230 den</span>
            </li>
            <li>
                <span class="span1" data-translate="Salcë domatesh,mozzarella">Salcë domatesh,mozzarella</span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="span" data-translate="Pizza Prosciutto">Pizza Prosciutto</span>
                <span class="cmimi">280 den</span>            </li>
            <li>
                <span class="span1"data-translate="Salcë domatesh,mozzarella,mish i thatë">Salcë domatesh,mozzarella,mish i thatë</span>
            </li>
        </ul>
    
        <ul>
            <li>
                <span class="span" data-translate="Pizza Capricciosa">Pizza Capricciosa</span>
                <span class="cmimi">300 den</span>         
           </li>
            <li>
                <span class="span1"  data-translate="Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha">Salcë domatesh,mozzarella,
                    mish i thatë<br></br>sallamë,kërpudha</span>
            </li>
        </ul>
    

        <ul>
            <li>
                <span class="span"data-translate="Pizza Tuna">Pizza Tuna</span>
                <span class="cmimi">300 den</span>            
            </li>
            <li>
                <span class="span1"data-translate="Salcë domatesh,mozzarella,qepë,tuna">Salcë domatesh,mozzarella,qepë,tuna</span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="span" data-translate="Pizza 4 Stinët">Pizza 4 Stinët</span>
                <span class="cmimi">320 den</span>            </li>
            <li>
                <span class="span1"data-translate="Salcë domatesh,mozzarella,mish i thatë,sallamë,,tuna,kërpudha">Salcë domatesh,mozzarella,mish i thatë<br></br>sallamë,tuna,kërpudha</span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="span" data-translate="Pizza Quattro formaggi">Pizza Quattro formaggi</span>
                <span class="cmimi">330 den</span>            </li>
            <li>
                <span class="span1"data-translate="Salcë domatesh,mozzarella,,parmezan,gorgonzollë,djath i bardhë">Salcë domatesh,mozzarella,parmezan,gorgonzollë,djath i bardhë</span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="span" data-translate="Pizza Vegetariana">Pizza Vegetariana</span>
                <span class="cmimi">280 den</span>            </li>
            <li>
                <span class="span1"data-translate="Salcë domatesh,mozzarella,domate të kuqe,domate e zezë,speca,ullinj">Salcë domatesh,mozzarella,domate të kuqe,<br></br>domate e zezë,speca,ullinj</span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="span" data-translate="Pizza Reale">Pizza Reale</span>
                <span class="cmimi">280 den</span>            </li>
            <li>
                <span class="span1"data-translate="Salcë domatesh,mozzarella,parmezan,mish i thatë,rukola">Salcë domatesh, mozzarella,parmezan,<br></br>mish i thatë,rukola</span>
            </li>
        </ul>
        <BackToTopButton />
        <ul>
            <li>
                <span class="span" data-translate="Pizza Calzone">Pizza Calzone</span>
                <span class="cmimi">250 den</span>            </li>
            <li>
                <span class="span1"data-translate="pizza e mbyllur,Salcë domatesh,mozzarella,mish i thatë,sallamë,kërpudha">pizza e mbyllur,Salcë domatesh, mozzarella,<br></br>mish i thatë,sallamë,kërpudha</span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="span" data-translate="Pizza Funghi">Pizza Funghi</span>
                <span class="cmimi">250 den</span>            </li>
            <li>
                <span class="span1"data-translate="Salcë domatesh,mozzarella,kërpudha">Salcë domatesh, mozzarella,kërpudha</span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="span" data-translate="Pizza Elita">Pizza Elita</span>
                <span class="cmimi">330 Den</span>            </li>
            <li>
                <span class="span1"data-translate="e përzier">e përzier</span>
            </li>
        </ul>
        <ul>
            <li>
                <span class="span" data-translate="Pizza Familjare">Pizza Familjare</span>
                <span class="cmimi">600 den</span>            </li>
            <li>
                <span class="span1"data-translate="Salcë domatesh,mozzarella,sallamë,mish i thatë,suxhuk,kërpudha,ullinj">Salcë domatesh,mozzarella,sallamë,<br></br>mish i thatë,suxhuk,kërpudha,ullinj</span>
            </li>
        </ul>
        <ul>
                <li>
                    <span class="span" data-translate="Pizza Familjare">Pizza Familjare</span>
                    <span class="cmimi">700 den</span>            </li>
                <li>
                    <span class="span1"data-translate="Salcë domatesh,mozzarella,sallamë,mish i thatë,suxhuk,kërpudha,ullinj">Salcë domatesh,mozzarella,sallamë,<br></br>mish i thatë,suxhuk,kërpudha,ullinj</span>
                </li>
        </ul>
        <ul>
                  <li>
                        <span class="span" data-translate="Pizza Sandwich">Pizza Sandwich</span>
                        <span class="cmimi">250 den</span>            
                  </li>
        </ul>
        <h2 data-translate="Pije freskuese">Pije freskuese</h2>
        <ul>
                  <li>
                        <span class="span" >Coca Cola</span>
                        <span class="cmimi">80 den</span>            
                  </li>
        </ul>
        <ul>
            <li>
                  <span class="span" >Fanta</span>
                  <span class="cmimi">80 den</span>            
            </li>
         </ul>
        <ul>
                <li>
                    <span class="span" >Schweppes</span>
                    <span class="cmimi">80 den</span>            
                </li>
        </ul>
        <ul>
            <li>
                  <span class="span" >Strumka</span>
                  <span class="cmimi">80 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Ujë mineral" >Ujë mineral</span>
                  <span class="cmimi">60 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Ujë pa gaz" >Ujë pa gaz</span>
                  <span class="cmimi">60 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Lëng Portokalli">Lëng Portokalli</span>
                  <span class="cmimi">80 den</span>            
            </li>
        </ul>

        <ul>
            <li>
                  <span class="span" data-translate="Lëng Dredhze">Lëng Dredhze</span>
                  <span class="cmimi">80 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Lëng Vishnje">Lëng Vishnje</span>
                  <span class="cmimi">80 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Lëng Pjeshke" >Lëng Pjeshke</span>
                  <span class="cmimi">80 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Lëng Molle" >Lëng Molle</span>
                  <span class="cmimi">80 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Multivitamin">Multivitamin</span>
                  <span class="cmimi">80 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span"  data-translate="Ice Tea">Ice Tea</span>
                  <span class="cmimi">80 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Limonadë" >Limonadë</span>
                  <span class="cmimi">50 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Boronicë" >Boronicë</span>
                  <span class="cmimi">50 den</span>            
            </li>
        </ul>
        <h2 data-translate="Ëmbëlsira">Ëmbëlsira</h2>
        <ul>
            <li>
                  <span class="span" >Banana split</span>
                  <span class="cmimi">150 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Sallat pemësh" >Sallat pemësh</span>
                  <span class="cmimi">120 den</span>            
            </li>
        </ul>

        <ul>
            <li>
                  <span class="span" data-translate="Akullore">Akullore</span>
                  <span class="cmimi">30 den</span>            
            </li>
        </ul>
        <h2 data-translate="Pije të nxehta">Pije të nxehta</h2>
        <ul>
            <li>
                  <span class="span" data-translate="Kafe" >Kafe</span>
                  <span class="cmimi">50 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span"  >Nescafe</span>
                  <span class="cmimi">60 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span"  data-translate="Makiato">Makiato</span>
                  <span class="cmimi">50 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Makiato e madhe">Makiato e madhe</span>
                  <span class="cmimi">70 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Kapuçino me shllag">Kapuçino me shllag</span>
                  <span class="cmimi">70 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span"data-translate="Çaj">Çaj</span>
                  <span class="cmimi">50 den</span>            
            </li>
        </ul>
        <ul>
            <li>
                  <span class="span" data-translate="Qumësht">Qumësht</span>
                  <span class="cmimi">50 den</span>            
            </li>
        </ul>
        <button onClick={Phone} class="telefon"><span class="material-symbols-outlined">call</span>Telefono</button>
        <button onClick={Location} class="telefon"><span class="material-symbols-outlined">location_on</span>Location</button>
        <button class="telefon"><span class="material-symbols-outlined">wifi</span>elita2000</button>
        </div>
      </>
    )
  }

  
  export default Elitamenu;